import React from 'react';

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}): JSX.Element => <>{condition ? wrapper(children) : children}</>;

export default ConditionalWrapper;
