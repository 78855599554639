/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "🌌 discretize.eu"), "\n", React.createElement(_components.p, null, "Welcome to the guides repository of the ", React.createElement(_components.a, {
    href: "https://discretize.eu"
  }, "discretize.eu"), " website.\nAll our content is located here and can easily be updated using GitHub's versioning system or our ", React.createElement(_components.a, {
    href: "https://staging.discretize.eu/cms"
  }, "content management system"), "."), "\n", React.createElement(_components.h2, null, "👨‍💻 How to contribute"), "\n", React.createElement(_components.p, null, "All information about the contribution process can be found ", React.createElement(_components.a, {
    href: "https://staging.discretize.eu/contribute/"
  }, "here")), "\n", React.createElement(_components.h2, null, "📃 Discretize Markdown Language"), "\n", React.createElement(_components.p, null, "All our guides are written using an extended version of the ", React.createElement(_components.a, {
    href: "http://commonmark.org/"
  }, "Markdown Language"), "."), "\n", React.createElement(_components.h2, null, "📞 Communication"), "\n", React.createElement(_components.p, null, "Feel free to join our ", React.createElement(_components.a, {
    href: "https://discord.gg/Qdt7nFY"
  }, "discord server"), " for state-of-the-art team communication."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
